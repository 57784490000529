import { Button, Checkbox, Collapse, Typography, Radio, Row, Col, Form, InputNumber, Slider } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useEffect, useState } from 'react';
import { useQueryParameters } from '../../../hooks';
import { useNavigate } from 'react-router';


const { Title } = Typography;
const { Panel } = Collapse;

interface Props {
	availability_status: string;
	vendors: any[];
	vendors_selected: string;
	shapes: any[];
	shape_selected: string;
	types: any[];
	types_selected: string;
	outer_diameter_gte?: number;
	outer_diameter_lte?: number;
	inner_diameter_gte?: number;
	inner_diameter_lte?: number;
	width_gte?: number;
	width_lte?: number;
	height_gte?: number;
	height_lte?: number;
}

export const VendorMaterialFilter = (props: Props) => {
	const queryParams = useQueryParameters();
	const navigate = useNavigate();

	const [reset, setReset] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState([props.availability_status]);
	const [selectedVendors, setSelectedVendors] = useState<string[]>(props.vendors_selected.split(','));
	const [selectedShape, setSelectedShape] = useState<string>(props.shape_selected);
	const [selectedTypes, setSelectedTypes] = useState<string[]>(props.types_selected.split(','));

	// Range values
	const [outerDiameterRange, setOuterDiameterRange] = useState<[number, number]>([0.001, 144]);
	const [innerDiameterRange, setInnerDiameterRange] = useState<[number, number]>([0.001, 144]);
	const [widthRange, setWidthRange] = useState<[number, number]>([0.001, 144]);
	const [heightRange, setHeightRange] = useState<[number, number]>([0.001, 144]);

	// Initial range values
	const [initialHeightRange, setInitialHeightRange] = useState<[number, number]>([0, 0]);
	const [initialWidthRange, setInitialWidthRange] = useState<[number, number]>([0, 0]);
	const [initialInnerDiameterRange, setInitialInnerDiameterRange] = useState<[number, number]>([0, 0]);
	const [initialOuterDiameterRange, setInitialOuterDiameterRange] = useState<[number, number]>([0, 0]);


	useEffect(() => {
		if (!props.shape_selected && props.shapes.length > 0) {
			props.shapes.forEach((shape) => shape.display_name === 'Rectangle' ? setSelectedShape(shape.id) : null);
		}
	}, [props.shapes]);

	useEffect(() => {
		// Set initial values
		if (props.height_lte && props.height_gte) {
			if (initialHeightRange[0] == 0 && initialHeightRange[1] == 0) {
				setInitialHeightRange([props.height_gte, props.height_lte]);
				setHeightRange([props.height_gte, props.height_lte]);
			}
		}

		if (props.width_lte && props.width_gte) {
			if (initialWidthRange[0] == 0 && initialWidthRange[1] == 0) {
				setInitialWidthRange([props.width_gte, props.width_lte]);
				setWidthRange([props.width_gte, props.width_lte]);
			}
		}

		if (props.inner_diameter_lte && props.inner_diameter_gte) {
			if (initialInnerDiameterRange[0] == 0 && initialInnerDiameterRange[1] == 0) {
				setInitialInnerDiameterRange([props.inner_diameter_gte, props.inner_diameter_lte]);
				setInnerDiameterRange([props.inner_diameter_gte, props.inner_diameter_lte]);
			}
		}

		if (props.outer_diameter_lte && props.outer_diameter_gte) {
			if (initialOuterDiameterRange[0] == 0 && initialOuterDiameterRange[1] == 0) {
				setInitialOuterDiameterRange([props.outer_diameter_gte, props.outer_diameter_lte]);
				setOuterDiameterRange([props.outer_diameter_gte, props.outer_diameter_lte]);
			}
		}
	}, [props.height_lte, props.height_gte, props.width_lte, props.width_gte, props.inner_diameter_lte, props.inner_diameter_gte, props.outer_diameter_lte, props.outer_diameter_gte]);

	useEffect(() => {
		queryParams.set('availability_status', 'Available');
		queryParams.set('shape', props.shapes.find((x) => x.display_name === 'Rectangle')?.id);
		navigate({ search: queryParams.toString() });
	}, []);

	useEffect(() => {
		queryParams.set('availability_status', 'Available');
		queryParams.set('shape', props.shapes.find((x) => x.display_name === 'Rectangle')?.id);
		navigate({ search: queryParams.toString() });
	}, [reset]);

	const handleResetFilters = () => {
		setSelectedStatus(['Available']);
		setSelectedVendors([]);
		setSelectedShape(props.shapes.find((x) => x.display_name === 'Rectangle')?.id);
		setSelectedTypes([]);

		// reset ranges
		setHeightRange([initialHeightRange[0], initialHeightRange[1]]);
		setWidthRange([initialWidthRange[0], initialWidthRange[1]]);
		setInnerDiameterRange([initialInnerDiameterRange[0], initialInnerDiameterRange[1]]);
		setOuterDiameterRange([initialOuterDiameterRange[0], initialOuterDiameterRange[1]]);

		// reset initials
		setInitialHeightRange([initialHeightRange[0], initialHeightRange[1]]);
		setInitialWidthRange([initialWidthRange[0], initialWidthRange[1]]);
		setInitialInnerDiameterRange([initialInnerDiameterRange[0], initialInnerDiameterRange[1]]);
		setInitialOuterDiameterRange([initialOuterDiameterRange[0], initialOuterDiameterRange[1]]);

		queryParams.delete('availability_status');
		queryParams.delete('vendors');
		queryParams.delete('shape');
		queryParams.delete('types');
		queryParams.delete('outer_diameter__gte');
		queryParams.delete('outer_diameter__lte');
		queryParams.delete('inner_diameter__gte');
		queryParams.delete('inner_diameter__lte');
		queryParams.delete('width__gte');
		queryParams.delete('width__lte');
		queryParams.delete('height__gte');
		queryParams.delete('height__lte');

		navigate('/material/vendor-materials');
		setReset(!reset);
	};

	const handleSetQueryParams = (key: string, value: string) => {
		queryParams.set(key, value);
		navigate({ search: queryParams.toString() });
	};

	const handleStatusChange = (checkedValues: string[]) => {
		if (checkedValues.includes('Available') && checkedValues.includes('Unavailable')) {
			const lastSelected = checkedValues[checkedValues.length - 1];
			setSelectedStatus([lastSelected]);
			handleSetQueryParams('availability_status', lastSelected);
		} else {
			setSelectedStatus(checkedValues);
			if (checkedValues.length === 0) {
				queryParams.delete('availability_status');
			}
			navigate({ search: queryParams.toString() });
		}
	};

	const handleVendorChange = (checkedValues: string[]) => {
		setSelectedVendors(checkedValues);
		handleSetQueryParams('vendors', checkedValues.join(','));
	};

	const handleShapeChange = (e: any): void => {
		const value = e.target.value;
		setSelectedShape(value);
		const shapeName = getShapeNameById(value);

		if (
			shapeName === 'Rectangle' ||
			shapeName === 'Plate' ||
			shapeName === 'Channel' ||
			shapeName === 'Angle'
		) {
			queryParams.delete('outer_diameter__gte');
			queryParams.delete('outer_diameter__lte');
			queryParams.delete('inner_diameter__gte');
			queryParams.delete('inner_diameter__lte');
		} else if (shapeName === 'Round') {
			queryParams.delete('width__gte');
			queryParams.delete('width__lte');
			queryParams.delete('height__gte');
			queryParams.delete('height__lte');
			queryParams.delete('inner_diameter__gte');
			queryParams.delete('inner_diameter__lte');
		} else if (shapeName === 'Tube') {
			queryParams.delete('width__gte');
			queryParams.delete('width__lte');
			queryParams.delete('height__gte');
			queryParams.delete('height__lte');
			queryParams.delete('outer_diameter__gte');
			queryParams.delete('outer_diameter__lte');
		}

		handleSetQueryParams('shape', value);

	};

	const handleTypeChange = (checkedValues: string[]) => {
		setSelectedTypes(checkedValues);
		handleSetQueryParams('types', checkedValues.join(','));
	};

	const handleOuterDiameterChange = (value: [number, number]) => {
		setOuterDiameterRange(value);
	};

	const handleOuterDiameterChangeComplete = (value: [number, number]) => {
		setOuterDiameterRange(value);
		handleSetQueryParams('outer_diameter__gte', String(value[0]));
		handleSetQueryParams('outer_diameter__lte', String(value[1]));
	};

	const handleHeightChange = (value: [number, number]) => {
		setHeightRange(value);
	};

	const handleHeightChangeComplete = (value: [number, number]) => {
		setHeightRange(value);
		handleSetQueryParams('height__gte', String(value[0]));
		handleSetQueryParams('height__lte', String(value[1]));
	};

	const handleWidthChange = (value: [number, number]) => {
		setWidthRange(value);
	};

	const handleWidthChangeComplete = (value: [number, number]) => {
		setWidthRange(value);
		handleSetQueryParams('width__gte', String(value[0]));
		handleSetQueryParams('width__lte', String(value[1]));
	};

	const handleInnerDiameterChange = (value: [number, number]) => {
		setInnerDiameterRange(value);
	};

	const handleInnerDiameterChangeComplete = (value: [number, number]) => {
		setInnerDiameterRange(value);
		handleSetQueryParams('inner_diameter__gte', String(value[0]));
		handleSetQueryParams('inner_diameter__lte', String(value[1]));
	};

	const getShapeNameById = (id: string | undefined | null) => {
		if (id) {
			return props.shapes.find((x) => x.id === id)?.display_name;
		}
	};

	return (
		<div
			style={{
				background: '#FBFBFB',
				border: '1px solid #D9D9D9',
				borderRadius: '2px',
				flex: '0 0 250px',
			}}
		>
			<div
				style={{
					background: '#FBFBFB',
					borderBottom: '1px solid #D9D9D9',
					borderRadius: '2px',
				}}
			>
				<div className="flex justify-between items-center p-3">
					<Title
						level={5} style={{ margin: 0 }}>
						Filters
					</Title>
					<div>
						<Button type="link" onClick={handleResetFilters}>
							Reset
						</Button>
					</div>
				</div>
			</div>
			<div>
				<Collapse
					defaultActiveKey={['0', '2']}
					style={{ border: 'none' }}
					ghost={true}
				>
					<Panel
						header="Availability Status"
						key="0"
						style={{
							width: '100%',
							backgroundColor: 'white',
							borderBottom: '1px solid #d9d9d9',
						}}
					>
						<Checkbox.Group
							onChange={handleStatusChange}
							value={selectedStatus}
						>
							<div style={{ marginBottom: '8px', width: '100%' }}>
								<Checkbox value="Available">Available</Checkbox>
							</div>
							<div style={{ marginBottom: '8px', width: '100%' }}>
								<Checkbox value="Unavailable">Unavailable</Checkbox>
							</div>
						</Checkbox.Group>
					</Panel>

					<Panel
						header="Vendor"
						key="1"
						style={{
							width: '100%',
							backgroundColor: 'white',
							borderBottom: '1px solid #d9d9d9',
						}}
					>
						<Checkbox.Group
							value={selectedVendors}
							onChange={handleVendorChange}
						>
							<div className="flex flex-col"> {/* Asegura que los elementos estén en una columna */}
								{props.vendors.map((x) => (
									<div key={x.id} className="mb-2">
										<Checkbox value={x.id}>{x.display_name}</Checkbox>
									</div>
								))}
							</div>
						</Checkbox.Group>
					</Panel>

					<Panel
						header="Shape"
						key="2"
						style={{
							width: '100%',
							backgroundColor: 'white',
							borderBottom: '1px solid #d9d9d9',
						}}
					>
						<Radio.Group
							value={selectedShape}
							onChange={handleShapeChange}
						>
							{props.shapes.map((x) => (
								<div key={x.id} style={{ marginBottom: '8px' }}>
									<Radio value={x.id}>{x.display_name}</Radio>
								</div>
							))}
						</Radio.Group>
					</Panel>

					<Panel
						header="Type"
						key="3"
						style={{
							width: '100%',
							backgroundColor: 'white',
							borderBottom: '1px solid #d9d9d9',
						}}
					>
						<Scrollbars style={{ height: 250 }}>
							<Checkbox.Group
								value={selectedTypes}
								onChange={handleTypeChange}
							>
								{props.types.map((x) => (
									<div
										key={x.id}
										style={{ marginBottom: '8px', width: '100%' }}
									>
										<Checkbox value={x.id}>{x.display_name}</Checkbox>
									</div>
								))}
							</Checkbox.Group>
						</Scrollbars>
					</Panel>

					{getShapeNameById(selectedShape) === 'Round' ? (
						<Panel
							header="Outer Diameter"
							key="4"
							style={{
								width: '100%',
								backgroundColor: 'white',
								borderBottom: '1px solid #d9d9d9',
							}}
						>
							<Row gutter={8} align="middle">
								<Col span={12}>
									<Form.Item label="Min">
										<InputNumber
											min={initialOuterDiameterRange[0]}
											max={initialOuterDiameterRange[1]}
											step={0.25}
											value={outerDiameterRange[0]}
											onChange={(value) => {
												setOuterDiameterRange([
													Number(value),
													outerDiameterRange[1],
												]);
												handleOuterDiameterChangeComplete([
													heightRange[0],
													Number(value),
												]);
											}}
											style={{ width: '100%' }}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label="Max">
										<InputNumber
											min={initialOuterDiameterRange[0]}
											max={initialOuterDiameterRange[1]}
											step={0.25}
											value={outerDiameterRange[1]}
											onChange={(value) => {
												if (value) {
													setOuterDiameterRange([
														outerDiameterRange[0],
														Number(value),
													]);
													handleOuterDiameterChangeComplete([
														Number(value),
														outerDiameterRange[1],
													])
												}
											}}
											style={{ width: '100%' }}
										/>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Slider
										range
										min={initialOuterDiameterRange[0]}
										max={initialOuterDiameterRange[1]}
										step={0.25}
										value={outerDiameterRange}
										onChange={(value) => {
											handleOuterDiameterChange([
												value[0],
												value[1],
											]);
										}}
										onChangeComplete={(value) => {
											handleOuterDiameterChangeComplete([
												value[0],
												value[1],
											]);
										}}
										tooltip={{ formatter: (value) => `${value}"` }}
									/>
								</Col>
							</Row>
						</Panel>
					) : null}

					{(getShapeNameById(selectedShape) === 'Rectangle' ||
						getShapeNameById(selectedShape) === 'Plate' ||
						getShapeNameById(selectedShape) === 'Angle' ||
						getShapeNameById(selectedShape) === 'Channel') && (
						<>
							<Panel
								header="Height"
								key="6"
								style={{
									width: '100%',
									backgroundColor: 'white',
									borderBottom: '1px solid #d9d9d9',
								}}
							>
								<Row gutter={8} align="middle">
									<Col span={12}>
										<Form.Item label="Min">
											<InputNumber
												min={initialHeightRange[0]}
												max={initialHeightRange[1]}
												step={0.25}
												value={heightRange[0]}
												onChange={(value) => {
													if (value) {
														setHeightRange([Number(value), heightRange[1]]);
														handleHeightChangeComplete([
															Number(value),
															heightRange[1],
														]);
													}
												}}
												style={{ width: '100%' }}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label="Max">
											<InputNumber
												min={initialHeightRange[0]}
												max={initialHeightRange[1]}
												step={0.25}
												value={heightRange[1]}
												onChange={(value) => {
													if (value) {
														setHeightRange([heightRange[0], Number(value)]);
														handleHeightChangeComplete([
															heightRange[0],
															Number(value),
														]);
													}
												}}
												style={{ width: '100%' }}
											/>
										</Form.Item>
									</Col>
									<Col span={24}>
										<Slider
											range
											min={initialHeightRange[0]}
											max={initialHeightRange[1]}
											step={0.25}
											value={heightRange}
											onChange={(value) => {
												handleHeightChange([
													value[0],
													value[1],
												]);
											}}
											onChangeComplete={(value) => {
												handleHeightChangeComplete([
													value[0],
													value[1],
												]);
											}}
											tooltip={{ formatter: (value) => `${value}"` }}
										/>
									</Col>
								</Row>
							</Panel>
							<Panel
								header="Width"
								key="5"
								style={{
									width: '100%',
									backgroundColor: 'white',
									borderBottom: '1px solid #d9d9d9',
								}}
							>
								<Row gutter={8} align="middle">
									<Col span={12}>
										<Form.Item label="Min">
											<InputNumber
												min={initialWidthRange[0]}
												max={initialWidthRange[1]}
												step={0.25}
												value={widthRange[0]}
												onChange={(value) => {
													if (value) {
														setWidthRange([Number(value), widthRange[1]]);
														handleWidthChangeComplete([
															Number(value),
															widthRange[1],
														]);
													}
												}}
												style={{ width: '100%' }}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item label="Max">
											<InputNumber
												min={initialWidthRange[0]}
												max={initialWidthRange[1]}
												step={0.25}
												value={widthRange[1]}
												onChange={(value) => {
													if (value) {
														setWidthRange([widthRange[0], Number(value)]);
														handleWidthChangeComplete([
															widthRange[0],
															Number(value),
														]);
													}
												}}
												style={{ width: '100%' }}
											/>
										</Form.Item>
									</Col>
									<Col span={24}>
										<Slider
											range
											min={initialWidthRange[0]}
											max={initialWidthRange[1]}
											step={0.25}
											value={widthRange}
											onChange={(value) => {
												handleWidthChange([
													value[0],
													value[1],
												]);
											}}
											onChangeComplete={(value) => {
												handleWidthChangeComplete([
													value[0],
													value[1],
												]);
											}}
											tooltip={{ formatter: (value) => `${value}"` }}
										/>
									</Col>
								</Row>
							</Panel>
						</>
					)}

					{getShapeNameById(selectedShape) === 'Tube' && (
						<Panel
							header="Inner Diameter"
							key="7"
							style={{
								width: '100%',
								backgroundColor: 'white',
								borderBottom: '1px solid #d9d9d9',
							}}
						>
							<Row gutter={8} align="middle">
								<Col span={12}>
									<Form.Item label="Min">
										<InputNumber
											min={initialInnerDiameterRange[0]}
											max={initialInnerDiameterRange[1]}
											step={0.25}
											value={innerDiameterRange[0]}
											onChange={(value) => {
												if (value) {
													setInnerDiameterRange([Number(value), innerDiameterRange[1]]);
													handleInnerDiameterChangeComplete([
														Number(value),
														innerDiameterRange[1],
													]);
												}
											}}
											style={{ width: '100%' }}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label="Max">
										<InputNumber
											min={initialInnerDiameterRange[0]}
											max={initialInnerDiameterRange[1]}
											step={0.25}
											value={innerDiameterRange[1]}
											onChange={(value) => {
												if (value) {
													setInnerDiameterRange([innerDiameterRange[0], Number(value)]);
													handleInnerDiameterChangeComplete([
														innerDiameterRange[0],
														Number(value),
													]);
												}
											}}
											style={{ width: '100%' }}
										/>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Slider
										range
										min={initialInnerDiameterRange[0]}
										max={initialInnerDiameterRange[1]}
										step={0.25}
										value={innerDiameterRange}
										onChange={(value) => {
											handleInnerDiameterChange([
												value[0],
												value[1],
											]);
										}}
										onChangeComplete={(value) => {
											handleInnerDiameterChangeComplete([
												value[0],
												value[1],
											]);
										}}
										tooltip={{ formatter: (value) => `${value}"` }}
									/>
								</Col>
							</Row>
						</Panel>
					)}
				</Collapse>
			</div>
		</div>
	);
};
