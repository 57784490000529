import {
	Breadcrumb,
	Button,
	Card,
	Empty,
	Modal,
	TablePaginationConfig,
} from 'antd';
import { ReceivingFiler } from './ReceivingFilter.tsx';
import { useEffect, useState } from 'react';
import { ReceivingForm } from './ReceivingForm.tsx';
import { useGetMaterialQuery } from '../../../redux/api/material.ts';
import { useQueryParameters } from '../../../hooks';
import {
	MaterialObject,
	MaterialTypeObject,
} from '../../../interfaces/Material.Interface.ts';
import dayjs from 'dayjs';
import { MaterialDimensions } from '../../../components';
import { FilterValue } from 'antd/es/table/interface';
import { useNavigate } from 'react-router';
import PaginationComponent from '../../../components/Footers/Pagination.tsx';
import CustomResponsiveTable from '../../../components/CustomResponsiveTable';

interface MaterialQuery {
	page?: number;
	search?: string;
	ordering?: string;
	module?: string;
}

export const ListMaterialReceiving = () => {
	const navigate = useNavigate();
	const queryParams = useQueryParameters();
	const currentPageUrl = Number(queryParams.get('page')) || 1;
	const searchUrl = queryParams.get('search') || '';
	const ordering = queryParams.get('ordering') || '';

	const [showModalReceiving, setShowModalReceiving] = useState(false);
	const [materials, setMaterials] = useState<MaterialObject[]>([]);
	const [materialSelected, setMaterialSelected] =
		useState<MaterialObject | null>(null);
	const [totalMaterials, setTotalMaterials] = useState<number>(0);

	const materialItemsQuery: MaterialQuery = {};
	materialItemsQuery.module = 'material_received';

	if (currentPageUrl) {
		materialItemsQuery.page = currentPageUrl;
	}

	if (searchUrl && searchUrl !== '') {
		materialItemsQuery.search = searchUrl;
	}

	if (ordering && ordering !== '') {
		materialItemsQuery.ordering = ordering;
	}

	const { data, isLoading, refetch } = useGetMaterialQuery(materialItemsQuery);

	const refetchData = () => {
		refetch();
	};

	useEffect(() => {
		if (data) {
			const results = data.results.filter((item: MaterialObject) => {
				return (
					item.remnant === false &&
					item.status !== 'Delivered' &&
					item.status !== 'Draft'
				);
			});
			setTotalMaterials(data.count || 0);
			setMaterials(results);
			setMaterialSelected(null);
		}
	}, [data]);

	const handleOpenModal = (id: string) => {
		// Todo: make a query to get the material order item details
		const material = materials.find((item: MaterialObject) => item.id === id);
		if (material) {
			setMaterialSelected(material);
		}
		setShowModalReceiving(true);
	};

	const handleCancelReceiving = () => {
		setShowModalReceiving(false);
	};

	const handleTableChange = (
		_pagination: TablePaginationConfig,
		_filters: Record<string, FilterValue | null>,
		sorter: { column: string; columnKey: string; order: string },
		extra: { action: string }
	) => {
		console.log(sorter);
		if (extra.action === 'sort') {
			const orderType = sorter.order;
			const column =
				orderType === 'ascend' || orderType === undefined
					? sorter.columnKey
					: '-' + sorter.columnKey;
			if (sorter.column !== undefined) {
				queryParams.set('ordering', column);
			} else {
				queryParams.delete('ordering');
			}
			navigate({ search: queryParams.toString() });
		}
	};

	const tableColumns = [
		{
			title: 'Vendor Order Number',
			key: 'material_order__vendor_order_number',
			render: (record: MaterialObject) => {
				if (!record.material_order) {
					return '';
				} else {
					return record.material_order.vendor_order_number || '';
				}
			},
			sorter: true,
		},
		{
			title: 'Purchase Order Number',
			key: 'material_order__purchase_order_number',
			render: (record: MaterialObject) => {
				return record.material_order?.purchase_order_number;
			},
			sorter: (a: MaterialObject, b: MaterialObject) => {
				const valueA = a.material_order?.purchase_order_number || '';
				const valueB = b.material_order?.purchase_order_number || '';

				if (valueA === '' && valueB !== '') return 1;
				if (valueA !== '' && valueB === '') return -1;

				return valueA.localeCompare(valueB);
			},
		},
		{
			title: 'Material Type',
			key: 'type__display_name',
			render: (record: MaterialObject) => {
				return record.type.display_name || 'N/A';
			},
			sorter: (a: MaterialObject, b: MaterialObject) =>
				a.type.display_name.localeCompare(b.type.display_name),
		},
		{
			title: 'Material Shape',
			key: 'shape',
			render: (record: MaterialObject) => {
				return record.shape.display_name || 'N/A';
			},
			sorter: true,
		},
		{
			title: 'Material Size',
			key: 'material_dimensions',
			render: (record: MaterialTypeObject) => {
				return (
					<MaterialDimensions
						shape={record.shape?.display_name}
						width={record.width}
						width_unit={record.width_unit?.name}
						height={record.height}
						height_unit={record.height_unit?.name}
						inner_diameter={record.inner_diameter}
						inner_diameter_unit={record.inner_diameter_unit?.name}
						outer_diameter={record.outer_diameter}
						outer_diameter_unit={record.outer_diameter_unit?.name}
					/>
				);
			},
		},
		{
			title: 'Material Length',
			key: 'length',
			render: (record: MaterialObject) => {
				return `${record.length} ${record.length_unit.name} L.`;
			},
			sorter: true,
		},
		{
			title: 'Ordered / Delivered',
			key: 'quantity_ordered',
			render: (record: MaterialObject) => {
				return `${record.quantity_ordered} / ${record.quantity_total_received}`;
			},
			sorter: true,
		},
		{
			title: 'Promised Delivery Date',
			key: 'promised_delivery_date',
			render: (record: MaterialObject) => {
				if (!record.promised_delivery_date) {
					return 'N/A';
				} else {
					return (
						dayjs(record.promised_delivery_date).format('MMMM DD, YYYY') ||
						'N/A'
					);
				}
			},
			sorter: (a: MaterialObject, b: MaterialObject) => {
				const dateA = a.promised_delivery_date || '';
				const dateB = b.promised_delivery_date || '';
				return dateA.localeCompare(dateB);
			},
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			sorter: true,
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (_: string, record: any) => (
				<div className="flex flex-row">
					<Button
						type="primary"
						className="mr-1"
						onClick={() => handleOpenModal(record.id)}
					>
						Receive
					</Button>
				</div>
			),
		},
	];

	const tableFooter = () => {
		return (
			<div className="flex justify-between items-center">
				<div className="ml-auto">
					<PaginationComponent pageSize={20} total={totalMaterials} />
				</div>
			</div>
		);
	};

	const emptyTable = () => {
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={
					<span style={{ color: '#000' }}>
						There are currently no materials orders to be displayed.
					</span>
				}
			/>
		);
	};

	return (
		<>
			<div className="flex flex-column justify-between py-4">
				<h1 className="text-2xl font-bold m-0">Material Receiving</h1>
			</div>

			<Breadcrumb
				style={{ marginBottom: '1.5rem', marginTop: '.5rem' }}
				items={[
					{
						title: 'Home',
					},
					{
						title: 'Material Receiving',
					},
				]}
			/>

			<div style={{ marginBottom: '1rem' }}>
				<ReceivingFiler />
			</div>

			<Card className="shadow-sm" bodyStyle={{ padding: 0 }}>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<CustomResponsiveTable
						columns={tableColumns}
						data={materials}
						pagination={false}
						loading={isLoading}
						rowKey="id"
						footer={tableFooter}
						// eslint-disable-next-line
						//@ts-ignore
						onChange={handleTableChange}
						emptyTable={emptyTable}
					/>
				</div>
			</Card>

			<Modal
				title={'Receive Material'}
				centered={true}
				open={showModalReceiving}
				width={800}
				footer={false}
				onCancel={handleCancelReceiving}
			>
				{materialSelected && (
					<ReceivingForm
						refetchData={refetchData}
						material={materialSelected}
						onCancel={handleCancelReceiving}
					/>
				)}
			</Modal>
		</>
	);
};
