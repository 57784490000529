import { createSlice } from '@reduxjs/toolkit';

interface MaterialState {
	addMaterialModalVisible: boolean;
	addVendorMaterialModalVisible: boolean;
}

const initialState: MaterialState = {
	addMaterialModalVisible: false,
	addVendorMaterialModalVisible: false,
};

export const material = createSlice({
	name: 'material',
	initialState,
	reducers: {
		showAddMaterialModal: (state) => {
			state.addMaterialModalVisible = true;
		},
		closeAddMaterialModal: (state) => {
			state.addMaterialModalVisible = false;
		},
		showAddVendorMaterialModal: (state) => {
			state.addVendorMaterialModalVisible = true;
		},
		closeAddVendorMaterialModal: (state) => {
			state.addVendorMaterialModalVisible = false;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	showAddMaterialModal,
	closeAddMaterialModal,
	showAddVendorMaterialModal,
	closeAddVendorMaterialModal,
} = material.actions;

export default material.reducer;
