import { Navigate, Route, Routes } from 'react-router';
import ConnectDevice from '../pages/ConnectDevice';
import Material from '../pages/Material/Materials';
import Machines from '../pages/Machines/Machines';
import VersaBuilt from '../pages/VersaBuilt';
import ToolHolders from '../pages/ToolHolders';
import MachineCycleTime from '../pages/MachineCycleTime';
import FastlaneOrders from '../pages/Orders';
import AddMaterialOrder from '../pages/Material/AddMaterialOrder';
import MaterialOrders from '../pages/Material/MaterialOrders';
import MaterialOrderDetails from '../pages/Material/MaterialOrderDetails';
import OrderDetails from '../pages/Orders/OrderDetails';
import ToolHolderModels from '../pages/ToolHolderModels';
import ToolHoldersQRCodes from '../pages/ToolHolderQRCodes';
import AddOrder from '../pages/Orders/AddOrder';
import MachineStatuses from '../pages/MachineStatuses';
import Machine from '../pages/Machines/Machine';
import OrderLineItemDetails from '../pages/Orders/OrderLineItemDetails';
import MaterialRequests from '../pages/Material/MaterialRequest';
import OrderLineItems from '../pages/Orders/OrderLineItems';
import { ListMaterialReceiving } from '../pages/Material';
import ProductionQueues from '../pages/ProductionQueues';
import VendorMaterials from '../pages/Material/VendorMaterials';

const PrivateRoutes = () => {
	return (
		<div className="container max-w-full h-full">
			<Routes>
				<Route path="/" element={<Navigate to="orders" />} />
				<Route path="connect" element={<ConnectDevice />} />
				<Route path="orders" element={<FastlaneOrders />} />
				<Route path="orders" element={<FastlaneOrders />} />
				<Route path="orders/add" element={<AddOrder />} />
				<Route path="orders/:id" element={<OrderDetails />} />
				<Route path="orders/line-items" element={<OrderLineItems />} />
				<Route
					path="orders/line-items/:id"
					element={<OrderLineItemDetails />}
				/>
				<Route path="production/queues" element={<ProductionQueues />} />
				<Route path="material/materials" element={<Material />} />
				<Route path="material/orders" element={<MaterialOrders />} />
				<Route path="material/orders/add" element={<AddMaterialOrder />} />
				<Route path="material/orders/add/:id" element={<AddMaterialOrder />} />
				<Route path="material/orders/:id" element={<MaterialOrderDetails />} />
				<Route path="material/requests" element={<MaterialRequests />} />
				<Route path="material/receiving" element={<ListMaterialReceiving />} />
				<Route path="material/vendor-materials" element={<VendorMaterials />} />

				<Route path="machines" element={<Machines />} />
				<Route path="machines/:id" element={<Machine />} />
				<Route path="machines/statuses" element={<MachineStatuses />} />
				<Route
					path="versabuilt/mill-automation-systems"
					element={<VersaBuilt />}
				/>
				<Route path="tool-holders" element={<ToolHolders />} />
				<Route path="tool-holders/models" element={<ToolHolderModels />} />
				<Route path="tool-holders/qr-codes" element={<ToolHoldersQRCodes />} />
				<Route path="cycle-time" element={<MachineCycleTime />} />
			</Routes>
		</div>
	);
};

export default PrivateRoutes;
