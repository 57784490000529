import React from 'react';
import {Table} from 'antd';

import {TableProps, TablePaginationConfig} from 'antd/es/table';

import type {ColumnsType} from 'antd/es/table';

import {OrderListProps, Lineitem} from '../../interfaces/Ordel.Interface';

import {
    OrderMaterial,
    MaterialObject,
    MaterialTypeObject,
    IVendorMaterial,
} from '../../interfaces/Material.Interface';

import {MaterialRemenant} from '../../interfaces/MaterialRemenat';
import {
    MachineObject,
    machineRecord,
} from '../../interfaces/Machines.interfaces';

import {Versabuilt} from '../../interfaces/VersaBuilt.Interface';

import {
    ToolObject,
    Toolholdermodel,
} from '../../interfaces/ToolHolder.Interface';

import {DataType} from '../../interfaces/TableDataMaterial.interfaces';

interface IProps extends TableProps<any> {
    loading: boolean;
    columns:
        | ColumnsType<OrderListProps>
        | ColumnsType<Lineitem>
        | ColumnsType<OrderMaterial>
        | ColumnsType<MaterialRemenant>
        | ColumnsType<MaterialObject>
        | ColumnsType<MaterialTypeObject>
        | ColumnsType<MachineObject>
        | ColumnsType<machineRecord>
        | ColumnsType<Versabuilt>
        | ColumnsType<ToolObject>
        | ColumnsType<Toolholdermodel>
        | ColumnsType<DataType>;
    data:
        | OrderListProps[]
        | Lineitem[]
        | OrderMaterial[]
        | MaterialObject[]
        | MaterialRemenant[]
        | MaterialTypeObject[]
        | MachineObject[]
        | machineRecord[]
        | Versabuilt[]
        | ToolObject[]
        | Toolholdermodel[]
        | DataType[]
        | IVendorMaterial[]
        | undefined;
    emptyTable: React.ReactNode | (() => React.ReactNode);
    // onRow?: (record: any, index: number) => unknown;
    pagination?: TablePaginationConfig | false;
    widthPerColumn?: number;
    excludeWidthColumn?: boolean;
    tableMaxHeight?: number;
}

const CustomResponsiveTable: React.FC<IProps> = ({
                                                     loading,
                                                     columns,
                                                     data,
                                                     emptyTable,
                                                     pagination,
                                                     widthPerColumn = 145,
                                                     excludeWidthColumn = false,
                                                     tableMaxHeight = 95,
                                                     onRow,
                                                     onChange,
                                                     ...rest
                                                 }) => {
    const includeWidthColumns = columns.map((item) => {
        return {...item, width: widthPerColumn};
    });

    return (
        <Table
            className="records-list"
            columns={excludeWidthColumn ? columns : includeWidthColumns}
            rowKey={(record) => record.id.toString()}
            loading={loading}
            dataSource={data}
            locale={{emptyText: emptyTable}}
            pagination={pagination}
            onRow={onRow}
            onChange={onChange}
            scroll={{y: `calc(${tableMaxHeight}vh - 250px)`}}
            tableLayout="fixed"
            {...rest}
        />
    );
};

export default CustomResponsiveTable;
