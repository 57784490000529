import {api} from '../slices/api.ts';
import {
    IVendorMaterial,
    IVendorMaterialLength,
    IVendorMaterialResults,
} from '../../interfaces/Material.Interface';

export const vendorMaterialApiSlice = api.injectEndpoints({
    endpoints: (builder) => ({
        getVendorMaterials: builder.query<IVendorMaterialResults, {
            search?: string;
            page?: number,
            ordering?: string
        } | void>({
            query: (args) => {
                if (args) {
                    return {
                        url: 'material/vendor-materials/',
                        params: args
                    }
                }
                return 'material/vendor-materials/'
            },
        }),
        getVendorMaterialById: builder.query<IVendorMaterial, string>({
            query: (id) => `material/vendor-materials/${id}/`,
        }),
        createVendorMaterial: builder.mutation<
            IVendorMaterial,
            Partial<IVendorMaterial>
        >({
            query: (newVendorMaterial) => ({
                url: 'material/vendor-materials/',
                method: 'POST',
                body: newVendorMaterial,
            }),
        }),
        updateVendorMaterial: builder.mutation<
            IVendorMaterial,
            Partial<IVendorMaterial>
        >({
            query: ({id, ...patch}) => ({
                url: `material/vendor-materials/${id}/`,
                method: 'PATCH',
                body: patch,
            }),
        }),
        deleteVendorMaterial: builder.mutation<{ success: boolean }, string>({
            query: (id) => ({
                url: `material/vendor-materials/${id}/`,
                method: 'DELETE',
            }),
        }),
        getVendorMaterialLengths: builder.query<IVendorMaterialLength[], void>({
            query: () => 'material/vendor-material-lengths/',
        }),
        getVendorMaterialLengthById: builder.query<IVendorMaterialLength, string>({
            query: (id) => `material/vendor-material-lengths/${id}/`,
        }),
        createVendorMaterialLength: builder.mutation<
            IVendorMaterialLength,
            Partial<IVendorMaterialLength>
        >({
            query: (newVendorMaterialLength) => ({
                url: 'material/vendor-material-lengths/',
                method: 'POST',
                body: newVendorMaterialLength,
            }),
        }),
        updateVendorMaterialLength: builder.mutation<
            IVendorMaterialLength,
            Partial<IVendorMaterialLength>
        >({
            query: ({id, ...patch}) => ({
                url: `material/vendor-material-lengths/${id}/`,
                method: 'PATCH',
                body: patch,
            }),
        }),
        deleteVendorMaterialLength: builder.mutation<
            { success: boolean },
            Partial<IVendorMaterialLength>
        >({
            query: ({id}) => ({
                url: `material/vendor-material-lengths/${id}/`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetVendorMaterialsQuery,
    useGetVendorMaterialByIdQuery,
    useCreateVendorMaterialMutation,
    useUpdateVendorMaterialMutation,
    useDeleteVendorMaterialMutation,
    useGetVendorMaterialLengthsQuery,
    useGetVendorMaterialLengthByIdQuery,
    useCreateVendorMaterialLengthMutation,
    useUpdateVendorMaterialLengthMutation,
    useDeleteVendorMaterialLengthMutation,
} = vendorMaterialApiSlice;
