import 'react';
import {
	Button,
	Checkbox,
	Col,
	Flex,
	Form,
	Input,
	InputNumber,
	Modal,
	Row,
	Select,
	Tag,
} from 'antd';

import { useGetMachinesQuery } from '../../redux/api/machines.ts';
import { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useGetJawSetsQuery } from '../../redux/api/fixtures.ts';
import { useGetManufacturingProcessTypesQuery } from '../../redux/api/manufacturing.ts';
import { UnitSelector } from '../../components/UnitSelector/index.tsx';
import { useDispatch, useSelector } from 'react-redux';
import { closeAddManufacturingProcessModal } from '../../redux/slices/manufacturing.ts';
import { ManufacturingProcess } from '../../interfaces/Manufacturing.Interface.ts';
import { RootState } from '../../redux/store/index.ts';

interface Props {
	loading: boolean;
	onSubmit: (values: ManufacturingProcess) => void;
}

const AddManufacturingProcessModal = (props: Props) => {
	const [modal, modalContext] = Modal.useModal();

	const open = useSelector(
		(state: RootState) =>
			state.manufacturing.addManufacturingProcessModalVisible
	);
	const dispatch = useDispatch();

	const [form] = Form.useForm();

	const [processType, setProcessType] = useState<string>('');
	const [machine, setMachine] = useState<string>('');
	const [isStockDovetailed, setIsStockDovetailed] = useState<boolean>(false);

	const machinesQuery = useGetMachinesQuery(
		{
			machine_model__type: processType,
		},
		{ skip: !processType, refetchOnMountOrArgChange: true }
	);
	const manufacturingProcessTypesQuery = useGetManufacturingProcessTypesQuery();

	const jawSetsQuery = useGetJawSetsQuery();

	return (
		<>
			<Modal
				title={'Add Manufacturing Process'}
				open={open}
				onCancel={() => dispatch(closeAddManufacturingProcessModal())}
				centered={true}
				width={'90vw'}
				style={{ maxWidth: '1150px' }}
				styles={{
					header: { marginBottom: '2rem' },
					body: { maxHeight: '80vh', overflowY: 'auto', overflowX: 'hidden' },
				}}
				footer={
					<>
						<Button
							className="mr-2"
							onClick={() => dispatch(closeAddManufacturingProcessModal())}
							disabled={props.loading}
						>
							Cancel
						</Button>
						<Button
							form="create-manufacturing-process-form"
							type="primary"
							htmlType="submit"
							loading={props.loading}
						>
							Save
						</Button>
					</>
				}
			>
				<Form
					labelCol={{ span: 24 }}
					wrapperCol={{ span: 24 }}
					form={form}
					name="create-manufacturing-process-form"
					layout="vertical"
					onFinish={(values) => props.onSubmit(values)}
					autoComplete="off"
					initialValues={{
						setups: [
							{
								stock_facing_amount: 0.03,
								is_dovetailing_setup: false,
							},
						],
					}}
				>
					<Row gutter={[8, 16]}>
						<Col span={24}>
							<Row gutter={[8, 0]}>
								<Col md={8} sm={12} span={24}>
									<Form.Item
										label="Type"
										name={'process_type'}
										rules={[
											{
												required: true,
												message: 'Please select the process type',
											},
										]}
									>
										<Select
											allowClear={true}
											placeholder="Select process type"
											popupMatchSelectWidth={false}
											filterOption={false}
											onChange={(value) => {
												setProcessType(value);
												form.resetFields(['machine', 'setups']);
											}}
										>
											{manufacturingProcessTypesQuery?.data?.map((item) => (
												<Select.Option key={item.id} value={item.id}>
													{item.display_name}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col md={8} sm={12} span={24}>
									<Form.Item
										label="Machine"
										name={'machine'}
										rules={[
											{
												required: true,
												message: 'Please select the machine',
											},
										]}
									>
										<Select
											allowClear={true}
											placeholder="Select machine"
											popupMatchSelectWidth={false}
											filterOption={false}
											disabled={!processType}
											onChange={(value) => {
												setMachine(value);
												form.resetFields(['setups']);
											}}
										>
											{machinesQuery?.data?.map((order, index) => (
												<Select.Option key={index} value={order.id}>
													{order?.name}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col span={24}>
									<Form.Item label="Description" name={'description'}>
										<Input.TextArea />
									</Form.Item>
								</Col>
							</Row>
							{form.getFieldValue('process_type') &&
							form.getFieldValue('machine') ? (
								<>
									<Row gutter={[8, 16]}>
										{manufacturingProcessTypesQuery?.data?.find(
											(item) => item.id === processType
										)?.display_name === 'Milling' ? (
											<Col span={24}>
												<Checkbox
													value={isStockDovetailed}
													onChange={(e) => {
														setIsStockDovetailed(e.target.checked);
														form.setFields([
															{
																name: ['setups', 0, 'is_dovetailing_setup'],
																value: e.target.checked,
															},
														]);
													}}
												>
													Dovetailed Stock
												</Checkbox>
											</Col>
										) : null}
										<Form.List name={'setups'}>
											{(subFields, subOpt) => (
												<>
													{subFields.map((subField) => (
														<>
															<Col span={24} key={subField.key}>
																<div
																	className="rounded"
																	style={{ border: '1px solid #cbcbcb' }}
																>
																	<Flex
																		justify="space-between"
																		align="start"
																		className="p-3"
																	>
																		<Flex
																			align="baseline"
																			style={{ fontWeight: 'bold' }}
																		>
																			<Tag color="geekblue">
																				{`SETUP`} {subField.name + 1}
																			</Tag>
																			{subField.name == 0 &&
																			isStockDovetailed ? (
																				<Tag color="cyan">DOVETAILING</Tag>
																			) : null}
																		</Flex>
																		{subFields.length > 1 &&
																		subField.name != 0 ? (
																			<CloseOutlined
																				onClick={() => {
																					modal.confirm({
																						title: `Remove Setup ${
																							subField.name + 1
																						}?`,
																						centered: true,
																						okText: 'Yes',
																						cancelText: 'No',
																						onOk: () => {
																							subOpt.remove(subField.name);
																						},
																					});
																				}}
																			/>
																		) : null}
																	</Flex>
																	<Row gutter={[8, 16]} className="p-3">
																		{manufacturingProcessTypesQuery?.data?.find(
																			(item) => item.id === processType
																		)?.display_name === 'Milling' ? (
																			<>
																				<Col md={8} sm={12} span={24}>
																					<Form.Item
																						label="Vise"
																						name={[subField.name, 'vise']}
																						rules={[
																							{
																								required: true,
																								message:
																									'Please select a vise number',
																							},
																						]}
																					>
																						<Select
																							allowClear={true}
																							placeholder="Select Vise Number"
																							popupMatchSelectWidth={false}
																							filterOption={false}
																							disabled={
																								!processType || !machine
																							}
																						>
																							{machinesQuery?.data
																								?.find(
																									(item) => item.id === machine
																								)
																								?.vises?.map((vise) => (
																									<Select.Option
																										key={vise.id}
																										value={vise.id}
																									>
																										{`${vise.vise_number} - ${vise.mobility}`}
																									</Select.Option>
																								))}
																						</Select>
																					</Form.Item>
																				</Col>
																				<Col md={8} sm={12} span={24}>
																					<Form.Item
																						label="Jaw Set"
																						name={[subField.name, 'jaw_set']}
																						rules={[
																							{
																								required: true,
																								message:
																									'Please select a jaw set',
																							},
																						]}
																					>
																						<Select
																							allowClear={true}
																							placeholder="Select Jaw Set"
																							popupMatchSelectWidth={false}
																							filterOption={false}
																							disabled={!processType}
																						>
																							{jawSetsQuery?.data?.map(
																								(item) => (
																									<Select.Option
																										key={item.id}
																										value={item.id}
																									>
																										{item.display_name}
																									</Select.Option>
																								)
																							)}
																						</Select>
																					</Form.Item>
																				</Col>
																			</>
																		) : manufacturingProcessTypesQuery?.data?.find(
																				(item) => item.id === processType
																		  )?.display_name === 'Turning' ? (
																			<>
																				<Col md={8} sm={12} span={24}>
																					<Form.Item
																						label="Spindle"
																						name={[subField.name, 'spindle']}
																						rules={[
																							{
																								required: true,
																								message:
																									'Please select a vise number',
																							},
																						]}
																					>
																						<Select
																							allowClear={true}
																							placeholder="Select spindle"
																							popupMatchSelectWidth={false}
																							filterOption={false}
																							disabled={
																								!processType || !machine
																							}
																						>
																							{machinesQuery?.data
																								?.find(
																									(item) => item.id === machine
																								)
																								?.machine_model?.spindles?.map(
																									(spindle) => (
																										<Select.Option
																											key={spindle.id}
																											value={spindle.id}
																										>
																											{spindle.type}
																										</Select.Option>
																									)
																								)}
																						</Select>
																					</Form.Item>
																				</Col>
																			</>
																		) : null}
																		{subField.name == 0 ? (
																			<Col md={8} sm={12} span={24}>
																				<Form.Item
																					label="Stock Facing Amount"
																					name={[
																						subField.name,
																						'stock_facing_amount',
																					]}
																					rules={[
																						{
																							required: true,
																							message:
																								'Please enter the stock facing amount',
																						},
																					]}
																				>
																					<InputNumber
																						style={{
																							width: '100%',
																						}}
																						step={0.005}
																						defaultValue={0.03}
																						min={0}
																						max={1}
																						addonAfter={
																							<UnitSelector
																								name={subField.name.toString()}
																								formItemName={
																									'stock_facing_amount_unit'
																								}
																								disabled={!processType}
																							/>
																						}
																						disabled={!processType}
																					/>
																				</Form.Item>
																			</Col>
																		) : null}
																	</Row>
																</div>
															</Col>
															<Form.Item
																hidden={true}
																name={[subField.name, 'is_dovetailing_setup']}
																valuePropName="checked"
															/>
														</>
													))}
													<Col span={24}>
														<Button
															type="default"
															onClick={() =>
																subOpt.add({
																	is_dovetailing_setup: false,
																})
															}
															block
														>
															+ Add Setup
														</Button>
													</Col>
												</>
											)}
										</Form.List>
									</Row>
								</>
							) : null}
						</Col>
					</Row>
				</Form>
			</Modal>
			{modalContext}
		</>
	);
};

export default AddManufacturingProcessModal;
