import { Button, Flex, message, Tooltip, Typography, Upload } from 'antd';
import {
	CheckCircleFilled,
	DownloadOutlined,
	HourglassOutlined,
	UploadOutlined,
	WarningFilled,
	SyncOutlined,
} from '@ant-design/icons';
import { Lineitem } from '../../../interfaces/Ordel.Interface';
import { useUpdateOrderLineItemMutation } from '../../../redux/api/order';
import { useSyncToFusion360Mutation } from '../../../redux/api/order-line-item';
import { useState, useRef } from 'react';

const { Title, Text } = Typography;

interface Props {
	lineItem: Lineitem;
	isDisabled: boolean;
	refetchOrder: () => void;
	enableBalloonedDrawings: boolean;
}

export const FileDownloads = (props: Props) => {
	const [loading, setLoading] = useState(false);
	const [messageApi, contextHolder] = message.useMessage();

	const retryFusionRef: any = useRef({});

	const [updateOrderLineItem, updateOrderLineItemResult] =
		useUpdateOrderLineItemMutation();

	const [syncToFunsion360] = useSyncToFusion360Mutation();

	const handleUpdateOrderLineItem = async (id: string, file?: any) => {
		const formData = new FormData();
		if (file) {
			formData.append('ballooned_drawing_file', file);
		}
		const payload = {
			id: id,
			values: formData,
		};

		const response: any = await updateOrderLineItem(payload);

		if (response?.error) {
			messageApi.open({
				type: 'error',
				content: `Error encountered while attempting to update.`,
			});
		}

		messageApi.open({
			type: 'success',
			content: 'File uploaded successfully',
		});

		props.refetchOrder();
	};

	const handleSyncToFusion360 = async (lineItemId: string) => {
		setLoading(true);
		await syncToFunsion360({ id: lineItemId })
			.unwrap()
			.then(() => {
				props.refetchOrder();
				setLoading(false);
				messageApi.open({
					type: 'success',
					content: 'File successfully synced to Fusion 360.',
				});
			})
			.catch((err) => {
				setLoading(false);
				messageApi.open({
					type: 'error',
					content: `Error: ${err.data}`,
				});
				retryFusionRef.current[lineItemId] = true;
			});
	};

	return (
		<>
			<Flex vertical className="gap-2">
				<Title level={5}>Files</Title>
				<Flex wrap="wrap" className="gap-4">
					<Flex vertical={true} align="start">
						3D File
						{props.lineItem.step_file ? (
							<Tooltip
								title={decodeURIComponent(
									props?.lineItem.step_file?.split('media/')[1]?.split('?X')[0]
								)}
								placement="right"
							>
								<Button
									icon={<DownloadOutlined />}
									type="primary"
									onClick={() => window.open(props?.lineItem.step_file)}
									disabled={!props?.lineItem.step_file}
								>
									Download
								</Button>
							</Tooltip>
						) : (
							<Button
								icon={<HourglassOutlined />}
								type="primary"
								disabled={true}
							>
								Pending
							</Button>
						)}
					</Flex>
					<Flex vertical={true} align="start">
						Drawing File
						{props.lineItem.drawing_file ? (
							<Tooltip
								title={decodeURIComponent(
									props?.lineItem.drawing_file
										?.split('media/')[1]
										?.split('?X')[0]
								)}
								placement="right"
							>
								<Button
									icon={<DownloadOutlined />}
									type="primary"
									onClick={() =>
										window.open(
											props?.lineItem.drawing_file,
											'_blank',
											'width=600,height=400'
										)
									}
									disabled={!props?.lineItem.drawing_file}
								>
									Download
								</Button>
							</Tooltip>
						) : (
							<Button
								icon={<HourglassOutlined />}
								type="primary"
								disabled={true}
							>
								Pending
							</Button>
						)}
					</Flex>
					{props.lineItem.step_file ? (
						<Flex vertical={true} align="start">
							Fusion 360
							<div>
								{props?.lineItem.fusion_360_item_id !== null ? (
									<Text type="success">
										<CheckCircleFilled className="mr-1" />
										<span>Sync Successful</span>
									</Text>
								) : (
									<>
										<Text type="warning">
											<WarningFilled className="mr-1" />
											<span>Automatic Sync Failed</span>
										</Text>
										<Button
											className="ml-2"
											icon={<SyncOutlined />}
											onClick={() => {
												handleSyncToFusion360(props.lineItem?.id);
											}}
											loading={loading}
											danger={!!retryFusionRef.current[props?.lineItem?.id]} // Accessing the retryFusion from useRef
										>
											{retryFusionRef.current[props?.lineItem?.id]
												? 'Retry'
												: 'Sync Now'}
										</Button>
									</>
								)}
							</div>
						</Flex>
					) : null}
				</Flex>
				{props.enableBalloonedDrawings && (
					<Flex vertical={true} align="start">
						Ballooned Drawing
						{props?.lineItem?.ballooned_drawing_file ? (
							<>
								<Tooltip
									title={decodeURIComponent(
										props?.lineItem.ballooned_drawing_file
											?.split('media/')[1]
											?.split('?X')[0]
									)}
									placement="right"
								>
									<Button
										icon={<DownloadOutlined />}
										type="primary"
										onClick={() =>
											window.open(
												props?.lineItem.ballooned_drawing_file,
												'_blank',
												'width=600,height=400'
											)
										}
										disabled={!props?.lineItem.ballooned_drawing_file}
									>
										Download
									</Button>
								</Tooltip>
							</>
						) : (
							<Upload
								{...{ multiple: false, showUploadList: false }}
								beforeUpload={() => false}
								accept={'.pdf, .png, .jpg, .jpeg'}
								onChange={(info) => {
									if (info.file && info.file.size) {
										const isLt2M = info?.file?.size / 1024 / 1024 <= 20;

										if (!isLt2M) {
											message.error('File size must not exceed 20MB.', 10);

											return false;
										}

										handleUpdateOrderLineItem(props?.lineItem?.id, info.file);
									}
								}}
							>
								<Button
									icon={<UploadOutlined />}
									type="primary"
									// disabled={props?.isDisabled}
									loading={updateOrderLineItemResult.isLoading}
								>
									Upload
								</Button>
							</Upload>
						)}
					</Flex>
				)}
			</Flex>
			{contextHolder}
		</>
	);
};
