import React from 'react';
import { Button } from 'antd';
import googleIcon from '../../../assets/googleIcon.svg';
import { useGoogleLogin, TokenResponse } from '@react-oauth/google';

interface IProps {
	handleLogin: (value: TokenResponse) => void;
}

const GoogleLoginButton: React.FC<IProps> = ({ handleLogin }) => {
	const hanleGoogleLogin = useGoogleLogin({
		onSuccess: (tokenResponse) => handleLogin(tokenResponse),
	});

	return (
		<Button
			onClick={() => hanleGoogleLogin()}
			style={{
				display: 'inline-flex',
				alignItems: 'center',
				boxShadow:
					'0 2px 2px 0 rgba(0, 0, 0, .24), 0 0 1px 0 rgba(0, 0, 0, .24)',
				padding: 0,
				borderRadius: 2,
				border: '1px solid transparent',
				fontSize: 16,
				fontWeight: '500',
				fontFamily: 'Roboto, sans-serif',
				width: '220px',
			}}
			size="large"
		>
			<img src={googleIcon}></img>
			<span
				style={{
					paddingRight: 10,
					fontWeight: 500,
					paddingLeft: 10,
					paddingTop: 10,
					paddingBottom: 10,
				}}
			>
				Sign in with Google
			</span>
		</Button>
	);
};

export default GoogleLoginButton;
