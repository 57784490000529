import { Button, Card, Col, Form, Input, Layout, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthToken } from '../../../redux/slices/user';
import { Navigate } from 'react-router';
import { RootState } from '../../../redux/store';
import { useLoginMutation } from '../../../redux/api/auth';
import { AuthInterface } from '../../../interfaces/Auth.interfaces';
import { useState } from 'react';

import GoogleLoginButton from '../../../components/Auth/GoogleLoginButton';

export const Login = () => {
	const user = useSelector((state: RootState) => state.user);
	const [error, setError] = useState<string | null>(null);
	const [formData, setFormData] = useState({
		email: '',
		password: '',
	});

	const handleInputChange = (e: any) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const dispatch = useDispatch();
	const [login, result] = useLoginMutation();

	const handleSubmit = async (values: AuthInterface) => {
		await login({
			email: values.email,
			password: values.password,
		})
			.unwrap()
			.then((payload) => {
				dispatch(setAuthToken(payload?.access));
			})
			.catch((error) => {
				setError(error.data.detail);
			});
	};

	const isButtonDisabled = !formData.email || !formData.password;

	if (user.authToken) {
		return <Navigate to="/" />;
	}

	const responseGoogle = async (response: any): Promise<void> => {
		const token = response.access_token;
		const responseFromServer = await fetch(
			`${import.meta.env.VITE_API_URL}/shop/api/auth/google/`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					access_token: token,
				}),
			}
		);

		try {
			if (!responseFromServer.ok) {
				throw new Error(
					'Error in response from server' + responseFromServer.status
				);
			}
			const data = await responseFromServer.json();
			dispatch(setAuthToken(data?.access_token));
		} catch (error) {
			setError('Error logging in with Google');
		}
	};

	return (
		<Layout
			style={{
				height: '100%',
				minHeight: '100vh',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-around',
			}}
		>
			<Card style={{ maxWidth: '480px' }} className="shadow-lg">
				<h1 className="text-2xl font-bold mb-5">PartsBadger ERP Login</h1>

				<div className="flex justify-center">
					<GoogleLoginButton
						handleLogin={(tokenResponse) => responseGoogle(tokenResponse)}
					/>
				</div>

				<div className="flex items-center justify-center my-4">
					<div className="border-t border-gray-300 w-full"></div>
					<div className="text-gray-500 text-sm px-2">or</div>
					<div className="border-t border-gray-300 w-full"></div>
				</div>

				<Form
					onFinish={handleSubmit}
					layout={'vertical'}
					size="large"
					requiredMark={false}
				>
					<Row className="mb-0">
						<Col span={24}>
							<Form.Item
								name="email"
								rules={[
									{
										type: 'email',
										required: true,
										message: 'Please enter a valid email address',
									},
								]}
								hasFeedback={true}
								className="mb-0"
							>
								<Input
									name="email"
									placeholder={'Email'}
									autoComplete={'username'}
									disabled={result.isLoading}
									onChange={handleInputChange}
								/>
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name="password"
								rules={[
									{
										required: true,
										message: 'Password is required',
									},
									{
										min: 1,
										message: 'Password must have at least 8 characters',
									},
								]}
								hasFeedback
							>
								<Input.Password
									name="password"
									type={'password'}
									placeholder={'Password'}
									autoComplete={'current-password'}
									disabled={result.isLoading}
									onChange={handleInputChange}
								/>
							</Form.Item>
						</Col>

						{error && (
							<Col span={24}>
								<div className="text-red-500 pb-4">{error}</div>
							</Col>
						)}

						<Button
							type="primary"
							loading={result.isLoading}
							htmlType="submit"
							block
							disabled={isButtonDisabled}
						>
							Login
						</Button>
					</Row>
				</Form>
			</Card>
		</Layout>
	);
};
