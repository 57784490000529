import { useEffect, useState } from 'react';
import {
	Modal,
	Form,
	Input,
	Button,
	Select,
	InputNumber,
	Row,
	Col,
	Checkbox,
	Table,
	Popconfirm,
	Typography, notification,
} from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import {
	IVendorMaterial,
	IVendorMaterialLength,
} from '../../interfaces/Material.Interface';
import {
	useCreateVendorMaterialMutation,
	useUpdateVendorMaterialMutation,
	useDeleteVendorMaterialLengthMutation,
} from '../../redux/api/vendor-materials';
import { useDispatch } from 'react-redux';
import { closeAddVendorMaterialModal } from '../../redux/slices/material';
import {
	useGetMaterialVendorsQuery,
	useGetMaterialShapesQuery,
	useGetMaterialTypesQuery,
} from '../../redux/api/material';
import { UnitSelector } from '../UnitSelector';
import { useGetUnitsOfMeasureQuery } from '../../redux/api/core';

interface VendorMaterialModalProps {
	vendorMaterial?: IVendorMaterial | null;
	visible: boolean;
	setVisible: (visible: boolean) => void;
	refetchData: () => void;
}

const VendorMaterialModal = (props: VendorMaterialModalProps) => {
	const dispatch = useDispatch();

	const [form] = Form.useForm();

	const [createVendorMaterial, createVendorMaterialResult] = useCreateVendorMaterialMutation();
	const [updateVendorMaterial, updateVendorMaterialResult] = useUpdateVendorMaterialMutation();
	const [deleteVendorMaterialLength] = useDeleteVendorMaterialLengthMutation();

	const [loading, setLoading] = useState(false);
	const [lengths, setLengths] = useState<IVendorMaterialLength[]>(props.vendorMaterial?.lengths || []);

	const [selectedShape, setSelectedShape] = useState<string | null | undefined>(null);

	const vendorsQuery = useGetMaterialVendorsQuery();
	const shapesQuery = useGetMaterialShapesQuery();
	const typesQuery = useGetMaterialTypesQuery();
	const unitsQuery = useGetUnitsOfMeasureQuery();

	function onClose() {
		form.resetFields();
		setSelectedShape(null);
		setLengths([]);
		dispatch(closeAddVendorMaterialModal());
		props.setVisible(false);
	}

	const handleOk = async () => {
		try {
			const values = await form.validateFields();
			setLoading(true);
			const unit = unitsQuery?.data?.find((x) => x.name === 'IN')?.id;
			let payload: Omit<IVendorMaterial, 'id' | 'height_tolerance_lower' | 'height_tolerance_lower_unit' | 'height_tolerance_upper' | 'height_tolerance_upper_unit' | 'temper'> | null = null;
			if (unit) {
				payload = {
					availability_status: values.availability_status,
					vendor: values.vendor,
					shape: values.shape,
					type: values.type,
					width: values.width,
					width_fraction: values.width_fraction,
					width_unit: unit,
					height: values.height,
					height_fraction: values.height_fraction,
					height_unit: unit,
					outer_diameter: values.outer_diameter,
					outer_diameter_fraction: values.outer_diameter_fraction,
					outer_diameter_unit: unit,
					inner_diameter: values.inner_diameter,
					inner_diameter_fraction: values.inner_diameter_fraction,
					inner_diameter_unit: unit,
					vendor_part_number: values.vendor_part_number,
					vendor_description: values.vendor_description,
					wall_thickness: values.wall_thickness,
					wall_thickness_unit: unit,
					is_standard_size: values.is_standard_size,
					lengths: [],
				};
			}

			if (lengths.length === 0) {
				notification.error({
					message: 'Please add at least one length',
				});
				return;
			}

			const lengthsData: IVendorMaterialLength[] = [];
			lengths.map((length) => {
				lengthsData.push({
					id: length.id || '',
					length: length.length,
					length_unit: typeof length.length_unit === 'object' ? length.length_unit.id : length.length_unit,
					price: length.price,
				});
			});

			if (lengthsData.length > 0 && payload) {
				payload.lengths = lengthsData;
			}

			if (props.vendorMaterial) {
				await updateVendorMaterial({
					id: props.vendorMaterial.id,
					...payload,
				}).then((response: any) => {
					if (response.error){
						if (response.error.status === 400) {
							notification.error({
								message: 'Error Updating Vendor Material',
								description: response.error.data,
							});
						}
					} else {
						notification.success({
							message: 'Vendor Material Updated',
						});
						props.refetchData();
					}
				});
			} else if (payload) {
				await createVendorMaterial(payload).then((response: any) => {
					if (response.error){
						if (response.error.status === 400) {
							notification.error({
								message: 'Error Creating Vendor Material',
								description: response.error.data,
							});
						}
					} else {
						notification.success({
							message: 'Vendor Material Created',
							placement: 'topRight',
						});
						props.refetchData();
					}
				});
			}
		} catch (error) {
			console.error('Failed to save VendorMaterial:', error);
		} finally {
			setLoading(false);
		}
	};

	const handleCancel = () => {
		form.resetFields();
		onClose();
	};

	// Populate form with existing vendor material data if passed in
	useEffect(() => {
		if (props.vendorMaterial) {
			form.setFieldsValue({
				vendor: props.vendorMaterial.vendor?.id, // Use the ID for Select component
				shape: props.vendorMaterial.shape?.id, // Use the ID for Select component
				type: props.vendorMaterial.type?.id, // Use the ID for Select component
				width: props.vendorMaterial.width,
				width_fraction: props.vendorMaterial.width_fraction,
				height: props.vendorMaterial.height,
				height_fraction: props.vendorMaterial.height_fraction,
				outer_diameter: props.vendorMaterial.outer_diameter,
				outer_diameter_fraction: props.vendorMaterial.outer_diameter_fraction,
				inner_diameter: props.vendorMaterial.inner_diameter,
				inner_diameter_fraction: props.vendorMaterial.inner_diameter_fraction,
				vendor_part_number: props.vendorMaterial.vendor_part_number,
				vendor_description: props.vendorMaterial.vendor_description,
				wall_thickness: props.vendorMaterial.wall_thickness,
				is_standard_size: props.vendorMaterial.is_standard_size,
				availability_status: props.vendorMaterial.availability_status,
			});
			setSelectedShape(props.vendorMaterial.shape?.display_name);
			setLengths(props.vendorMaterial.lengths || []);
		} else {
			form.resetFields();
			setSelectedShape(null);
			setLengths([]);
		}
	}, [props.vendorMaterial, props.visible]);

	// Handle shape change
	const handleShapeChange = (value: string) => {
		setSelectedShape(
			shapesQuery?.data?.find((x) => x.id === value)?.display_name,
		);
		form.resetFields([
			'width',
			'height',
			'outer_diameter',
			'inner_diameter',
			'wall_thickness',
		]);
	};

	const handleDeleteLength = async (id: string) => {
		try {
			setLoading(true);
			await deleteVendorMaterialLength({ id: id });
			setLengths((prevLengths) =>
				prevLengths.filter((length) => length.id !== id),
			);
		} catch (error) {
			console.error('Failed to delete VendorMaterialLength:', error);
		} finally {
			setLoading(false);
		}
	};

	const handleAddLength = () => {
		const newLength: IVendorMaterialLength = {
			id: Math.random().toString(36).substr(2, 9),
			length: 1,
			length_unit: unitsQuery?.data?.find((x) => x.name === 'IN')?.id || '',
			price: 0,
		};
		setLengths([...lengths, newLength]);
	};

	const handleUpdateLength = (id: string, key: string, value: any) => {
		const newLengths = lengths.map((length) => {
			if (length.id === id) {
				return { ...length, [key]: value };
			}
			return length;
		});
		setLengths(newLengths);
	};

	useEffect(() => {
		if (
			createVendorMaterialResult.isSuccess ||
			updateVendorMaterialResult.isSuccess
		) {
			form.resetFields();
			onClose();
		}
	}, [createVendorMaterialResult, updateVendorMaterialResult]);

	const lengthColumns = [
		{
			title: 'Length (inches)',
			key: 'length',
			render: (record: IVendorMaterialLength) => (
				<Form.Item
					name={`lengths_${record.id}_length`}
					initialValue={record.length || 1}
					noStyle
					rules={[{ required: true, message: 'Please input the length!' }]}
				>
					<InputNumber
						min={1}
						onChange={(value) => {
							if (record.id) handleUpdateLength(record.id, 'length', value);
						}}
						style={{ width: '100%' }}
						addonAfter={
							<UnitSelector
								initialValue={
									unitsQuery?.data?.find((x) => x.name === 'IN')?.id
								}
								name={'length_unit'}
								disabled={true}
							/>
						}
					/>
				</Form.Item>
			),
		},
		{
			title: 'Price',
			dataIndex: 'price',
			key: 'price',
			render: (_: string, record: IVendorMaterialLength) => (
				<Form.Item
					name={`lengths_${record.id}_price`}
					initialValue={record.price || 0}
					noStyle
				>
					<InputNumber
						min={0}
						precision={3}
						onChange={(value) => {
							if (record.id) handleUpdateLength(record.id, 'price', value);
						}}
						style={{ width: '100%' }}
						placeholder="Price"
					/>
				</Form.Item>
			),
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (_: string, record: IVendorMaterialLength) => (
				<>
					<Popconfirm
						title="Are you sure to delete this length?"
						onConfirm={() => record.id && handleDeleteLength(record.id)}
						okText="Yes"
						cancelText="No"
					>
						<Button
							type="link"
							icon={<CloseOutlined />}
							style={{ color: 'red' }}
						/>
					</Popconfirm>
				</>
			),
		},
	];

	return (
		<Modal
			open={props.visible}
			title={props.vendorMaterial ? 'Update Vendor Material' : 'Add New Vendor Material'}
			maskClosable={false}
			width="850px"
			onOk={handleOk}
			onCancel={handleCancel}
			destroyOnClose={true}
			confirmLoading={loading}
			centered={true}
			styles={{
				body: {
					height: '80vh',
					maxHeight: '850px',
					overflowY: 'scroll',
					overflowX: 'hidden',
				},
			}}
		>
			<Form form={form} layout="vertical">
				<Row gutter={[8, 16]}>
					<Col span={8}>
						<Form.Item
							name="vendor"
							label="Vendor"
							rules={[{ required: true, message: 'Please select a vendor!' }]}
						>
							<Select
								placeholder="Select a vendor"
								options={vendorsQuery?.data?.map((x) => {
									return { label: x.display_name, value: x.id };
								})}
							/>
						</Form.Item>
					</Col>

					<Col span={8}>
						<Form.Item
							name="shape"
							label="Shape"
							rules={[{ required: true, message: 'Please select a shape!' }]}
						>
							<Select
								placeholder="Select a shape"
								onChange={handleShapeChange}
								options={shapesQuery?.data?.map((x) => {
									return { label: x.display_name, value: x.id };
								})}
							/>
						</Form.Item>
					</Col>

					<Col span={8}>
						<Form.Item
							name="type"
							label="Type"
							rules={[{ required: true, message: 'Please select a type!' }]}
						>
							<Select
								placeholder="Select a type"
								options={typesQuery?.data?.map((x) => {
									return { label: x.display_name, value: x.id };
								})}
							/>
						</Form.Item>
					</Col>
				</Row>

				{(selectedShape === 'Rectangle' ||
					selectedShape === 'Plate' ||
					selectedShape === 'Angle' ||
					selectedShape === 'Channel') && (
					<Row gutter={[8, 16]}>
						<Col span={8}>
							<Form.Item
								name="width"
								label="Width (inches)"
								rules={[
									{
										required: true,
										message: 'Please input the width!',
									},
								]}
							>
								<InputNumber
									min={0.001}
									max={144}
									placeholder="Width"
									style={{ width: '100%' }}
									addonAfter={
										<UnitSelector
											initialValue={shapesQuery?.data?.find((x) => x.display_name === 'IN')?.id}
											formItemName={'width_unit'}
											disabled={true}
										/>
									}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="width_fraction" label="Width Fraction">
								<Input placeholder="Width Fraction" />
							</Form.Item>
						</Col>
					</Row>
				)}

				{(selectedShape === 'Rectangle' ||
					selectedShape === 'Plate' ||
					selectedShape === 'Angle' ||
					selectedShape === 'Channel') && (
					<Row gutter={[8, 16]}>
						<Col span={8}>
							<Form.Item
								name="height"
								label="Height (inches)"
								rules={[
									{
										required: true,
										message: 'Please input the height!',
									},
								]}
							>
								<InputNumber
									min={0.001}
									max={144}
									placeholder="Height"
									style={{ width: '100%' }}
									addonAfter={
										<UnitSelector
											initialValue={
												shapesQuery?.data?.find((x) => x.display_name === 'IN')
													?.id
											}
											formItemName={'height_unit'}
											disabled={true}
										/>
									}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="height_fraction" label="Height Fraction">
								<Input placeholder="Height Fraction" />
							</Form.Item>
						</Col>
					</Row>
				)}

				{(selectedShape === 'Round' || selectedShape === 'Tube') && (
					<Row gutter={[8, 16]}>
						<Col span={8}>
							<Form.Item
								name="outer_diameter"
								label="Outer Diameter (inches)"
								rules={[
									{
										required: true,
										message: 'Please input the outer diameter!',
									},
								]}
							>
								<InputNumber
									min={0.001}
									max={144}
									placeholder="Outer Diameter"
									style={{ width: '100%' }}
									addonAfter={
										<UnitSelector
											initialValue={
												shapesQuery?.data?.find((x) => x.display_name === 'IN')
													?.id
											}
											formItemName={'outer_diameter_unit'}
											disabled={true}
										/>
									}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								name="outer_diameter_fraction"
								label="Outer Diameter Fraction"
							>
								<Input placeholder="Outer Diameter Fraction" />
							</Form.Item>
						</Col>
					</Row>
				)}

				{selectedShape === 'Tube' && (
					<Row gutter={[8, 16]}>
						<Col span={8}>
							<Form.Item
								name="inner_diameter"
								label="Inner Diameter (inches)"
								rules={[
									{
										required: true,
										message: 'Please input the inner diameter!',
									},
								]}
							>
								<InputNumber
									min={0.001}
									max={144}
									placeholder="Inner Diameter"
									style={{ width: '100%' }}
									addonAfter={
										<UnitSelector
											initialValue={
												shapesQuery?.data?.find((x) => x.display_name === 'IN')
													?.id
											}
											formItemName={'inner_diameter_unit'}
											disabled={true}
										/>
									}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								name="inner_diameter_fraction"
								label="Inner Diameter Fraction"
							>
								<Input placeholder="Inner Diameter Fraction" />
							</Form.Item>
						</Col>
					</Row>
				)}

				{(selectedShape === 'Angle' || selectedShape === 'Channel') && (
					<Row gutter={[8, 16]}>
						<Col span={8}>
							<Form.Item
								name="wall_thickness"
								label="Wall Thickness (inches)"
								rules={[
									{
										required: true,
										message: 'Please input the wall thickness!',
									},
								]}
							>
								<InputNumber
									min={0.001}
									max={144}
									placeholder="Wall Thickness"
									style={{ width: '100%' }}
									addonAfter={
										<UnitSelector
											initialValue={
												shapesQuery?.data?.find((x) => x.display_name === 'IN')
													?.id
											}
											formItemName={'wall_thickness_unit'}
											disabled={true}
										/>
									}
								/>
							</Form.Item>
						</Col>
					</Row>
				)}

				{/* Vendor Part Number */}
				<Form.Item
					name="vendor_part_number"
					label="Vendor Part Number"
					rules={[
						{
							required: true,
							message: 'Please input the vendor part number!',
						},
					]}
				>
					<Input placeholder="Vendor Part Number" />
				</Form.Item>

				<Form.Item name="vendor_description" label="Vendor Description">
					<Input.TextArea placeholder="Vendor Description" />
				</Form.Item>

				<Row>
					<Col span={8}>
						<Form.Item name="availability_status" label="Availability Status" initialValue={'Available'}>
							<Select
								options={[
									{
										value: 'Available',
										label: 'Available',

									},
									{
										value: 'Unavailable',
										label: 'Unavailable',
									},
								]}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Form.Item name="is_standard_size" valuePropName="checked">
					<Checkbox>Standard Material</Checkbox>
				</Form.Item>

				<Typography.Title level={5}>
					<span style={{ fontSize: 16, color: 'red' }}>*</span> Available Lengths
				</Typography.Title>

				{lengths && (
					<>
						<Table
							dataSource={lengths}
							columns={lengthColumns}
							rowKey="id"
							pagination={false}
							size="small"
						/>
						<Button
							type="dashed"
							onClick={handleAddLength}
							icon={<PlusOutlined />}
							style={{ width: '100%', marginTop: 16 }}
						>
							Add Length
						</Button>
					</>
				)}
			</Form>
		</Modal>
	);
};

export default VendorMaterialModal;
