import { useState, useEffect } from 'react';

const RESIZE_EVENT = 'resize';
const UNDEFINED_VALUE = undefined;

interface Size {
    width: number | undefined;
    height: number | undefined;
}

const useWindowSize = (): Size => {
    const [windowSize, setWindowSize] = useState<Size>({
        width: UNDEFINED_VALUE,
        height: UNDEFINED_VALUE,
    });

    useEffect(() => {
        const handleResize = () =>
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });

        window.addEventListener(RESIZE_EVENT, handleResize);
        handleResize();

        return () => window.removeEventListener(RESIZE_EVENT, handleResize);
    }, []);

    return windowSize;
};

export { useWindowSize };
