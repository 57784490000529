import {Empty, Button} from 'antd';
import CustomResponsiveTable from '../CustomResponsiveTable';
import {IVendorMaterial} from '../../interfaces/Material.Interface';
import {CheckCircleFilled} from '@ant-design/icons';


interface Props {
    data: IVendorMaterial[];
    isLoading: boolean;
    recordsTotal: number;
    currentPage: number;
    onChangePage: (page: number) => void;
    selectedShape: string | undefined;
    setSelectedVendorMaterial: (record: IVendorMaterial) => void;
    setShowWriteModalVendorMaterial: (value: boolean) => void;
}

const VendorMaterialsTable = (props: Props) => {

    const tableColumns: any = [
        {
            title: 'Vendor',
            render: (record: IVendorMaterial) => `${record?.vendor?.display_name}`,
            visible: true,
            sorter: true,
        },
        {
            title: 'Material Type',
            render: (record: IVendorMaterial) => `${record?.type?.display_name}`,
            visible: true,
            sorter: true,
        },
        {
            title: 'Width',
            render: (record: IVendorMaterial) => {
                return `${record?.width} ${typeof record?.width_unit === 'object' ? record?.width_unit?.name : ''}`;
            },
            visible:
                props.selectedShape === 'Rectangle' ||
                props.selectedShape === 'Plate' ||
                props.selectedShape === 'Angle' ||
                props.selectedShape === 'Channel',
            key: 'width',
            sorter: true,
        },
        {
            title: 'Height',
            render: (record: IVendorMaterial) => {
                return `${record?.height} ${typeof record?.height_unit === 'object' ? record?.height_unit?.name : ''}`;
            },
            visible:
                props.selectedShape === 'Rectangle' ||
                props.selectedShape === 'Plate' ||
                props.selectedShape === 'Angle' ||
                props.selectedShape === 'Channel',
            key: 'height',
            sorter: true,
        },
        {
            title: 'Outer Diameter',
            render: (record: IVendorMaterial) => {
                return `${record?.outer_diameter} ${typeof record?.outer_diameter_unit === 'object' ? record?.outer_diameter_unit?.name : ''}`;
            },
            visible: props.selectedShape === 'Round' || props.selectedShape === 'Tube',
            key: 'outer_diameter',
            sorter: true,
        },
        {
            title: 'Inner Diameter',
            render: (record: IVendorMaterial) => {
                return `${record?.inner_diameter} ${typeof record?.inner_diameter_unit === 'object' ? record?.inner_diameter_unit?.name : ''}`;
            },
            visible: props.selectedShape === 'Tube',
            key: 'inner_diameter',
            sorter: true,
        },
        {
            title: 'Wall Thickness',
            render: (record: IVendorMaterial) => {
                return `${record?.wall_thickness} ${typeof record?.wall_thickness_unit === 'object' ? record?.wall_thickness_unit?.name : ''}`;
            },
            visible:
                props.selectedShape === 'Angle' ||
                props.selectedShape === 'Channel',
            key: 'wall_thickness',
            sorter: true,
        },
        {
            title: 'Vendor Part #',
            render: (record: IVendorMaterial) =>
                `${record?.vendor_part_number ? record?.vendor_part_number : ''}`,
            visible: true,
            key: 'vendor_part_number',
            sorter: true,
        },
        {
            title: 'Vendor Description',
            render: (record: IVendorMaterial) => record.vendor_description,
            key: 'vendor_description',
            visible: true,
            sorter: true,
            ellipsis: true,
        },
        {
            title: 'Availability Status',
            render: (record: IVendorMaterial) => record.availability_status,
            key: 'availability_status',
            visible: true,
            ellipsis: true,
        },
        {
            title: 'Standard',
            render: (record: IVendorMaterial) =>
                record.is_standard_size ? (
                    <div style={{color: '#52c41a'}}>
                        <CheckCircleFilled/> Standard
                    </div>
                ) : null,
            visible: true,
            sorter: true,
        },
        {
            title: 'Lengths',
            visible: true,
            render: (record: IVendorMaterial) => {
                return record.lengths.map((length) => {
                    if (typeof length.length_unit === 'object') {
                        return `${length.length} ${length.length_unit.name}`;
                    }
                }).join(', ');
            },
            sorter: true,
        },
        {
            title: 'Actions',
            key: 'select',
            render: (record: IVendorMaterial) => (
                <div>
                    <Button
                        type="link"
                        onClick={() => {
                            props.setSelectedVendorMaterial(record);
                            props.setShowWriteModalVendorMaterial(true);
                        }}
                    >
                        View/Edit
                    </Button>
                </div>
            ),
            visible: true,
        },
    ].filter((col) => col.visible);


    return (
        <>
            <div
                style={{
                    background: '#FBFBFB',
                    border: '1px solid #D9D9D9',
                    borderRadius: '2px',
                    flex: '0 0 calc(100% - 250px)',
                    maxWidth: 'calc(100% - 250px)',
                }}
            >
                <CustomResponsiveTable
                    scroll={{x: '720px'}}
                    columns={tableColumns}
                    data={props.data.length > 0 ? props.data : []}
                    loading={props.isLoading}
                    emptyTable={emptyTable}
                    rowKey="id"
                    pagination={{
                        pageSize: 20,
                        current: props.currentPage,
                        pageSizeOptions: [],
                        total: props.recordsTotal,
                        onChange: (page) => {
                            props.onChangePage(page);
                        },
                        defaultPageSize: 20,
                        showSizeChanger: false,
                    }}
                />
            </div>
        </>
    );
};

const emptyTable = () => {
    return (
        <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
                <span style={{color: '#000'}}>
					There are currently no materials to be displayed.
				</span>
            }
        />
    );
};

export default VendorMaterialsTable;
