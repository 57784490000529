import { Button, Col, Flex, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Header from '../../../components/Header';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useGetVendorMaterialsQuery } from '../../../redux/api/vendor-materials.ts';
import { IVendorMaterial } from '../../../interfaces/Material.Interface.ts';
import VendorMaterialsTable from '../../../components/VendorMaterialsTable';
import { VendorMaterialFilter } from './VendorMaterialFilter.tsx';
import {
	useGetMaterialShapesQuery,
	useGetMaterialTypesQuery,
	useGetMaterialVendorsQuery,
} from '../../../redux/api/material.ts';
import VendorMaterialModal from '../../../components/VendorMaterialModal';

interface IQueryParams {
	page?: number;
	ordering?: string;
	availability_status?: string;
	vendors?: string;
	shape?: string;
	types?: string;
	outer_diameter__gte?: string;
	outer_diameter__lte?: string;
	inner_diameter__gte?: string;
	inner_diameter__lte?: string;
	width__gte?: string;
	width__lte?: string;
	height__gte?: string;
	height__lte?: string;
}


function VendorMaterials() {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const currentPageUrl = Number(queryParams.get('page')) || 1;
	const ordering = queryParams.get('ordering') || '';
	const availability_status = queryParams.get('availability_status') || '';
	const vendors = queryParams.get('vendors') || '';
	const shape = queryParams.get('shape') || '';
	const types = queryParams.get('types') || '';
	const outer_diameter__gte = queryParams.get('outer_diameter__gte') || '';
	const outer_diameter__lte = queryParams.get('outer_diameter__lte') || '';
	const inner_diameter__gte = queryParams.get('inner_diameter__gte') || '';
	const inner_diameter__lte = queryParams.get('inner_diameter__lte') || '';
	const width__gte = queryParams.get('width__gte') || '';
	const width__lte = queryParams.get('width__lte') || '';
	const height__gte = queryParams.get('height__gte') || '';
	const height__lte = queryParams.get('height__lte') || '';


	const vendorMaterialQuery: IQueryParams = {};

	if (currentPageUrl) {
		vendorMaterialQuery.page = currentPageUrl;
	}

	if (ordering && ordering !== '') {
		vendorMaterialQuery.ordering = ordering;
	}

	if (availability_status && availability_status !== '') {
		vendorMaterialQuery.availability_status = availability_status;
	}

	if (vendors && vendors !== '') {
		vendorMaterialQuery.vendors = vendors;
	}

	if (shape && shape !== '') {
		vendorMaterialQuery.shape = shape;
	}

	if (types && types !== '') {
		vendorMaterialQuery.types = types;
	}

	if (outer_diameter__gte && outer_diameter__gte !== '') {
		vendorMaterialQuery.outer_diameter__gte = outer_diameter__gte;
	}

	if (outer_diameter__lte && outer_diameter__lte !== '') {
		vendorMaterialQuery.outer_diameter__lte = outer_diameter__lte;
	}

	if (inner_diameter__gte && inner_diameter__gte !== '') {
		vendorMaterialQuery.inner_diameter__gte = inner_diameter__gte;
	}

	if (inner_diameter__lte && inner_diameter__lte !== '') {
		vendorMaterialQuery.inner_diameter__lte = inner_diameter__lte;
	}

	if (width__gte && width__gte !== '') {
		vendorMaterialQuery.width__gte = width__gte;
	}

	if (width__lte && width__lte !== '') {
		vendorMaterialQuery.width__lte = width__lte;
	}

	if (height__gte && height__gte !== '') {
		vendorMaterialQuery.height__gte = height__gte;
	}

	if (height__lte && height__lte !== '') {
		vendorMaterialQuery.height__lte = height__lte;
	}

	const { data, isLoading, refetch } = useGetVendorMaterialsQuery(vendorMaterialQuery, {
		refetchOnFocus: true,
		refetchOnMountOrArgChange: true,
	});

	const refetchData = () => {
		refetch();
	};

	const shapesQuery = useGetMaterialShapesQuery();

	const vendorsQuery = useGetMaterialVendorsQuery();
	const typesQuery = useGetMaterialTypesQuery();

	const [vendorMaterials, setVendorMaterials] = useState<IVendorMaterial[]>([]);
	const [currentPage, setCurrentPage] = useState<number>(currentPageUrl);
	const [totalRecords, setTotalRecords] = useState<number>(0);

	const [selectedVendorMaterial, setSelectedVendorMaterial] = useState<IVendorMaterial | null>(null);
	const [showWriteModalVendorMaterial, setShowWriteModalVendorMaterial] = useState<boolean>(false);


	const pageChanged = async (page: number) => {
		queryParams.set('page', page.toString());
		navigate({ search: queryParams.toString() });
		setCurrentPage(page);
	};

	useEffect(() => {
		if (data) {
			setVendorMaterials(data.results as IVendorMaterial[]);
			setTotalRecords(data.count ? data.count : 0);
		}
	}, [data]);

	const getShapeNameById = (id: string) => {
		if (id && shapesQuery.data) {
			return shapesQuery.data.find((x) => x.id === id)?.display_name;
		}
	};

	const getValue = (field: string, limit: number, dataField: string) => {
		if (limit) {
			return Number(limit);
		}
		if (data) {
			const results: any = data.results;
			const sorted = results.map((x: any) => x[field]).sort((a: any, b:any) => (dataField === 'gte' ? a - b : b - a));
			return Number(sorted[0]);
		}
	};


	return (
		<>
			<Flex justify="space-between" align="center">
				<Header
					title="Vendor Materials"
					icon={<PlusOutlined />}
					// handleOnClick={() => dispatch(showAddMaterialModal())}
					breadcrumbItems={[
						{
							title: <Link to="/orders">Home</Link>,
						},
						{
							title: 'Vendor Materials',
						},
					]}
				/>
				<Button
					type="primary"
					icon={<PlusOutlined />}
					onClick={() => setShowWriteModalVendorMaterial(true)}
				>
					Add Vendor Material
				</Button>
			</Flex>

			<Row>
				<Col span={24}>
					<div className="flex flex-row gap-2 items-start mb-5">
						{shapesQuery.data && (
							<VendorMaterialFilter
								availability_status={availability_status ? availability_status : 'Available'}
								vendors={vendorsQuery.data ? vendorsQuery.data : []}
								vendors_selected={vendors}
								shapes={shapesQuery.data}
								shape_selected={shape}
								types={typesQuery.data ? typesQuery.data : []}
								types_selected={types}
								outer_diameter_gte={getValue('outer_diameter', Number(outer_diameter__gte), 'gte')}
								outer_diameter_lte={getValue('outer_diameter', Number(outer_diameter__lte), 'lte')}
								inner_diameter_gte={getValue('inner_diameter', Number(inner_diameter__gte), 'gte')}
								inner_diameter_lte={getValue('inner_diameter', Number(inner_diameter__lte), 'lte')}
								width_gte={getValue('width', Number(width__gte), 'gte')}
								width_lte={getValue('width', Number(width__lte), 'lte')}
								height_gte={getValue('height', Number(height__gte), 'gte')}
								height_lte={getValue('height', Number(height__lte), 'lte')}
							/>
						)}
						<VendorMaterialsTable
							data={vendorMaterials}
							recordsTotal={totalRecords}
							currentPage={currentPage}
							onChangePage={pageChanged}
							isLoading={isLoading}
							selectedShape={getShapeNameById(shape) ? getShapeNameById(shape) : 'Rectangle'}
							setSelectedVendorMaterial={data => setSelectedVendorMaterial(data)}
							setShowWriteModalVendorMaterial={setShowWriteModalVendorMaterial}
						/>
					</div>
				</Col>
			</Row>

			<VendorMaterialModal
				vendorMaterial={selectedVendorMaterial}
				visible={showWriteModalVendorMaterial}
				setVisible={(value: boolean) => {
					setShowWriteModalVendorMaterial(value);
					setSelectedVendorMaterial(null);
				}}
				refetchData={refetchData}
			/>
		</>
	);
}

export default VendorMaterials;
